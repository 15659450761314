import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout/layout'
import PuxMetaTags from '../components/shared/PuxMetaTags'
import WidgetBuilder from '../components/builders/widget-builder'
import BreadCrumbs from '../components/BreadCrumbs'
import { localizedPathType } from '../components/PuxLanguageSelector/PuxLanguageSelector'

const AboutUs = ({ data, pageContext }: any) => {
  const page = data.orchard.puxDesignAboutUs[0]

  return (
    <Layout localizedPath={page.localization?.localizations}>
      <PuxMetaTags {...pageContext.metaTagsData} />
      <BreadCrumbs
        default={true}
        center={false}
        items={[
          {
            path: page.path,
            displayText: page.displayText,
          },
        ]}
      />
      <WidgetBuilder widgetBuilderData={pageContext.widgetsTop} />

      <section className="">
        <div className="pux-container pb-20 pt-20">
          <div className="management">
            {page.aboutUsManagememnt.contentItems.map((item: ManagementProps) => (
              <div className="management-item" key={item.contentItemId}>
                <div className="management-item-photo">
                  <img
                    loading="lazy"
                    src={item.puxMediaSelector.puxMediaSelectorImage.resizePaths[0]}
                    alt={item.managementName}
                  />
                </div>
                <h4 className="management-item-name">{item.managementName}</h4>
                <div className="management-item-nick">{item.managementNick}</div>
                <div
                  className="management-item-description"
                  dangerouslySetInnerHTML={{
                    __html: item.managementDescription.html?.replace(/font-size: 1rem;/g, ``),
                  }}
                ></div>

                {item.managementPhone ? (
                  <div className="management-item-phone">
                    <a href={`tel:${item.managementPhone}`}>{item.managementPhone}</a>
                  </div>
                ) : null}

                <div className="management-item-mail">
                  <a className="btn-link" href={`mailto:${item.managementMail}`}>
                    {item.managementMail}
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <WidgetBuilder widgetBuilderData={pageContext.widgetsBottom} />
    </Layout>
  )
}

export default AboutUs

interface ManagementProps {
  contentItemId: string
  managementDescription: {
    html: string
  }
  managementMail: string
  managementName: string
  managementNick: string
  managementPhone: string
  puxMediaSelector: {
    puxMediaSelectorImage: {
      resizePaths: [string]
    }
  }
  localization: {
    localizations: localizedPathType[]
  }
}

export const query = graphql`
  query($pagePath: String = "") {
    orchard {
      puxDesignAboutUs(first: 1, where: { path: $pagePath }) {
        path
        displayText
        aboutUsManagememnt {
          contentItems {
            ... on Orchard_PuxDesignManagement {
              contentItemId
              managementDescription {
                html
              }
              managementMail
              managementName
              managementNick
              managementPhone
              puxMediaSelector {
                puxMediaSelectorImage {
                  resizePaths(width: 1920, mode: "Min", quality: 75)
                }
              }
            }
          }
        }
        localization {
          localizations {
            ... on Orchard_PuxDesignAboutUs {
              path
              published
              localization {
                culture
              }
            }
          }
        }
      }
    }
  }
`
